import React, { FC } from "react"
import { IPageTemplateProps } from "./Page"
import { PageTitle } from "components/Shared/PageTitle"
import { BackgroundImage } from "components/Shared/BackgroundImage"
import { PageWrapper } from "components/Shared/PageWrapper"
import styled from "styled-components"
import FeaturedSlides from "components/Slider/FeaturedSlides"
import { spacing } from "styles/spacing"
import { breakpoint } from "styles/breakpoints"
import { ContentWrapper } from "components/Shared/ContentWrapper"
import Search from "components/Search/"
import { useSearchIndex } from "hooks/searchIndex"
import SEO from "components/Seo"
import Breadcrumbs from "components/Breadcumbs"

const Container = styled.div`
  padding: 0;
  @media ${breakpoint.md} {
    padding: 0 ${spacing.sm}px;
  }
`

const BlogTemplate: FC<IPageTemplateProps> = ({
  pageContext: { title, template, type, id, categoryImage, posts, seo, link },
}) => {
  const index = useSearchIndex()

  const breadcrumbs = [
    { text: "Hem", url: "/" },
    { text: "Blogg", url: "/blogg" },
    { text: title, url: link },
  ]

  return (
    <>
      <SEO
        title={seo ? seo.title : title}
        description={seo ? seo.metaDesc : null}
        image={
          seo && seo.opengraphImage && seo.opengraphImage.sourceUrl
            ? seo.opengraphImage.sourceUrl
            : null
        }
      />
      <BackgroundImage
        style={{
          backgroundImage: `url(${
            categoryImage.categoryImage
              ? categoryImage.categoryImage.sourceUrl
              : null
          })`,
        }}
      />
      <PageWrapper>
        <PageTitle>{title}</PageTitle>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {Boolean(posts.nodes.length) ? (
          <Container>
            <FeaturedSlides
              breakpoints={{
                768: { slidesPerView: 2, spaceBetween: spacing.lg },
                1024: { slidesPerView: 3, spaceBetween: spacing.xl },
              }}
              featured={posts.nodes.slice(0, 10)}
            />
          </Container>
        ) : null}

        <ContentWrapper style={{ marginTop: 10 }}>
          <Search
            searchIndex={index}
            filter={{
              type: template ? template : type,
              blog: id,
            }}
          />
        </ContentWrapper>
      </PageWrapper>
    </>
  )
}

export default BlogTemplate
