import React, { FC, useState } from "react"
import SwiperCore, { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Container } from "components/Shared/Container"
import { IFeaturedContentProps } from "types"
import Card from "components/Shared/Card"
import { generateContentObject } from "utils"

interface IFeaturedSlidesProps {
  featured: IFeaturedContentProps[]
  breakpoints: {
    [ratio: string]: {
      slidesPerView: number
      spaceBetween: number
    }
  }
}

SwiperCore.use([Pagination])

const FeaturedSlides: FC<IFeaturedSlidesProps> = ({
  featured,
  breakpoints,
}) => {
  const [slideHeight, setSlideHeight] = useState(450)
  const setSlideHeightFn = () => {
    document.getElementsByClassName("swiper-slide")[0]
      ? setSlideHeight(
          document.getElementsByClassName("swiper-slide")[0].clientWidth
        )
      : null
  }

  return (
    <Container>
      <Swiper
        slidesPerView={1}
        pagination={{ clickable: true }}
        onSwiper={() => {
          setSlideHeightFn()
        }}
        breakpoints={breakpoints}
      >
        {featured.map(slide => {
          const content = generateContentObject(slide)
          return (
            <SwiperSlide
              key={slide.id}
              style={{
                height: slideHeight,
              }}
            >
              <Card content={content} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Container>
  )
}

export default FeaturedSlides
